import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { View, Flex, color } from "../components/Styles"
import { Row } from "."
import { useTranslation } from "react-i18next"

export default () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title={t("联系")} />

      <Row>
        <View scale={3} mt={1.5} weight={"bold"}>
          {t("联系我们")}
        </View>
      </Row>

      <Row my={[2.5, 5]} pt={[2.5, 5]} bt={color.gray4}>
        <Flex flexWrap responsive>
          <Info label={t("商务合作")} detail="bd@liebi.com"></Info>
          <Info label={t("节点代建")} detail="node@liebi.com"></Info>
          <Info label={t("资本投资/合作")} detail="capital@liebi.com"></Info>
          <Info label={t("问题反馈")} detail="support@liebi.com"></Info>
          <Info label={t("加入我们")} detail="join@liebi.com"></Info>
        </Flex>
      </Row>
    </Layout>
  )
}

const Info = ({ label, detail }) => (
  <View flex="50%">
    <View scale={1} color={color.liebi}>
      {label}
    </View>
    <View scale={2} mt={0.5} mb={2}>
      {detail}
    </View>
  </View>
)